<template>
    <div class="app-root">
        <transition name="fade">
            <div class="loader" v-if="isFetching">
                <img class="karos-logo loader-logo" src="@/assets/karos_logo.svg" alt="logo karos crown">
            </div>
        </transition>
        <div class="width-wrapper">
            <div class="circle-background" :class="'circle-' + index"  :key="'circle-' + index" v-for="index in 3"></div>
            <div class="logo-header">
                <img class="karos-logo" src="@/assets/karos_logo.svg" alt="logo karos crown">
                <img class="partner-logo" :src="logo" alt="">
            </div>
            <router-view> 
            </router-view>
            <p class="info-paragraph">*Vos covoiturages réalisés via l’application Karos sont automatiquement considérés pour la distribution du FMD, vous n’avez pas besoin de les déclarer.<a href="https://www.karos.fr/" referrerpolicy="origin"> Découvrir Karos.</a> </p>
        </div>
    </div>
</template>
<script>
import Axios from "axios"
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            isFetching: true,
            holding_id: '',
        }
    },
    computed: {
        ...mapGetters({
            apiUrl: 'getApiUrl',
            logo: 'getHoldingLogo'
        })
    },
    created() {
        this.fetch()
    },
    methods: {
        async fetch () {
            this.getParameters()
            if (!this.holding_id) return this.isFetching = false
            let mode = process.env.NODE_ENV == 'production' ? 'production' : 'dev'
            try {
                const res = await Axios.post(`${this.apiUrl}parameters/`, {holding_id: this.holding_id, mode: mode })
                this.$store.commit('updateFormParameters', res.data)
                this.isFetching = false
            } catch (err) {
                console.log(err)
                this.isFetching = false
                this.$router.push('/404')
            }
        },
        getParameters () {
            let path = window.location.pathname.split('/').filter(item => !!item)
            if (path.length < 2) return this.$router.push('/404')
            this.holding_id = path[1]
            this.$store.commit('updateHoldingId', this.holding_id)
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/index.scss'
</style>
