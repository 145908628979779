<template>
    <div class="end form-index light-shadow form-body">
        <div class="top-gradient"></div>
        <h2>Votre déclaration a bien été prise en compte ! </h2>
        <div class="round-corner-grey">
            <p>Un copie vous a été envoyée par email. </p>
            <p>Pour toute question concernant le forfait ou sa distribution, nous vous invitons à vous rapprocher de votre employeur. </p>
            <p>Ce dernier est susceptible de revenir vers vous lorsqu’un justificatif complémentaire à votre déclaration est nécessaire.</p>
        </div>
        <a href="https://www.karos.fr/" referrerpolicy="origin">
            <div class="round-corner-grey article-round-card">
                <img src="@/assets/cover.png" alt="cover article image" />            
                <p class="article-description">Et si vous covoituriez avec Karos ? C’est l’application partenaire de votre employeur. Le covoiturage domicile-travail devient <b>simple, rapide</b> et <b>efficace</b> avec Karos, et <b>éligible au FMD !</b></p>
            </div>
        </a>
        <div class="cta-aligner">
            <a href="https://www.karos.fr/" referrerpolicy="origin">
                <BaseButton label="Découvrir Karos"/>
            </a>
        </div>
    </div>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue'
export default {
    components : {
        BaseButton
    }
}
</script>
<style lang="scss">
.end{
    .round-corner-grey{
        p{
            font-size: 14px;
            line-height: 20px;
        }
    }
    .article-round-card{
        padding:0;
        margin: 20px 0;
        .article-description{
            padding:0 20px 20px 20px;

        }
    }
}
</style>