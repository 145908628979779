<template>
    <div class="input-text-component light-shadow">
        <label class="hidden-label" >{{ autofillLabel || label}}</label>
        <input
            class="semi-bold-paragraph"
            type="text"
            :value="modelValue"
            @input="updateInput"
            :name="label"
            :class="{'input-error': error}"
            ref="inputEl" >
        <p class="semi-bold-paragraph input-placeholder" v-if="!modelValue || modelValue.length == 0">{{label}}<span class="orange-text" v-if="required">*</span></p>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    setup () {
        const inputEl = ref(null)
        return { inputEl }
    },
    props: {
        modelValue: String,
        label: String,
        autofillLabel: String,
        required: Boolean,
        error: Boolean
    },
    emits: ['update:modelValue'],
    methods: {
        updateInput(event) {
            let value = event.target.value
            //this.computeWidth(value.length)
            this.$emit('update:modelValue', value)
        },
        computeWidth (length) {
            this.charWidth = 8
            let width = length * this.charWidth
            width = Math.min(Math.max(width, 100),300)
            this.inputEl.style.width =  `${width}px`
        }
    }
}
</script>
<style lang="scss">

.input-text-component{
    border-radius: 5px;
    display: inline-block;
    background: white;
    height: 48px;
    margin: 0 10px;
    position: relative;
    .hidden-label{
        display: none;;
    }
    [type="text"]{
        border-radius: 5px;
        border:none;
        height: 48px;
        padding: 0 10px;
        font-size: 14px;
        width: calc(100% - 20px);
    }
    .input-placeholder{
        position: absolute;
        top:50%;
        left:10px;
        font-size: 14px;
        margin: 0;
        transform: translate(0,-50%);
        pointer-events: none;
    }
    .input-error{
        border: #FF8000 1px solid;
    }
}

@media screen and (max-width: 764px) {
    .input-text-component{
        width: 100%;
        margin: 10px 0;
    }

}
</style>