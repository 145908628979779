export default {
    first_name: {
        get() { return this.$store.state.form.inputs.first_name },
        set(value) {this.$store.commit('updateFormProps', {props: 'first_name', value:value})}
    },
    last_name: {
        get() { return this.$store.state.form.inputs.last_name },
        set(value) {this.$store.commit('updateFormProps', {props: 'last_name', value:value})}
    },
    birth: {
        get() { return this.$store.state.form.inputs.birth },
        set(value) {this.$store.commit('updateFormProps', {props: 'birth', value:value})}
    },
    address: {
        get() { return this.$store.state.form.inputs.address },
        set(value) {this.$store.commit('updateFormProps', {props: 'address', value:value})}
    },
    postal_code: {
        get() { return this.$store.state.form.inputs.postal_code },
        set(value) {this.$store.commit('updateFormProps', {props: 'postal_code', value:value})}
    },
    city: {
        get() { return this.$store.state.form.inputs.city },
        set(value) {this.$store.commit('updateFormProps', {props: 'city', value:value})}
    },
    mobility_bike: {
        get() { return this.$store.state.form.inputs.mobility_bike },
        set(value) {this.$store.commit('updateFormProps', {props: 'mobility_bike', value:value})}
    },
    mobility_shared_personal: {
        get() { return this.$store.state.form.inputs.mobility_shared_personal },
        set(value) {this.$store.commit('updateFormProps', {props: 'mobility_shared_personal', value:value})}
    },
    mobility_shared_vehicle: {
        get() { return this.$store.state.form.inputs.mobility_shared_vehicle },
        set(value) {this.$store.commit('updateFormProps', {props: 'mobility_shared_vehicle', value:value})
        }
    },
    year: {
        get() { return this.$store.state.form.inputs.year },
        set(value) {this.$store.commit('updateFormProps', {props: 'year', value:value})}
    },
    month: {
        get() { return this.$store.state.form.inputs.month },
        set(value) {this.$store.commit('updateFormProps', {props: 'month', value:value})}
    },
    require_days: {
        get() { return this.$store.state.form.inputs.require_days },
        set(value) {this.$store.commit('updateFormProps', {props: 'require_days', value:value})}
    },
    require_trips: {
        get() { return this.$store.state.form.inputs.require_trips },
        set(value) {this.$store.commit('updateFormProps', {props: 'require_trips', value:value})}
    },
    require_distance: {
        get() { return this.$store.state.form.inputs.require_distance },
        set(value) {this.$store.commit('updateFormProps', {props: 'require_distance', value:value})}
    },
    email: {
        get() { return this.$store.state.form.inputs.email },
        set(value) {this.$store.commit('updateFormProps', {props: 'email', value:value})}
    },
    phone: {
        get() { return this.$store.state.form.inputs.phone },
        set(value) {this.$store.commit('updateFormProps', {props: 'phone', value:value})}
    },
    computeYearOptions () {
        let yearOtpions = []
        let actualYear = new Date()
        for (var i = 0; i < 2; i++) yearOtpions.push(actualYear.getFullYear() - i)
        return yearOtpions
    },
    computeMonthOptions () {
        return ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    }
}