<template>
    <div class="checkbox-component" :class="{'input-checkbox-error': error}">
        <input type="checkbox" :id="id" :modelValue="modelValue" @change="$emit('update:modelValue', $event.target.checked)"/>
        <label :for="id" class="semi-bold-paragraph"><slot></slot></label>
    </div>
</template>
<script>
export default {
    name: 'checkbox',
    props: {
        modelValue: {
            type: Boolean,
        },
        id: {  
            type: String,
        },
        error: Boolean
    },
    emits: ['update:modelValue']
}
</script>
<style lang="scss">
/* INPUTS */
.checkbox-component{
    display: flex;
    align-items:center;
    justify-content: flex-start;
    position:relative;

    label{
        text-align: left;
        cursor: pointer;
    }
    [type="checkbox"]{
        margin-right: 20px;
        cursor: pointer;
    }
    [type="checkbox"]:not(:checked), 
    [type="checkbox"]:checked {
        /* Cache la checkbox sans
           la rendre invisible aux
           lecteurs d'écran */
        opacity: 0.01;
        z-index: 333;
    }
    /* Aspect de la case */
    [type="checkbox"]:not(:checked) + label::before,
    [type="checkbox"]:checked + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width:20px;
        height:20px;
        border: 2px solid #DCE0E0;
        background: #FFF;
        border-radius: 5px;
        transform: translate(0,-50%);
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
        transition: all .275s;
    }

    /* Aspect de la coche */
    [type="checkbox"]:not(:checked) + label::after,
    [type="checkbox"]:checked + label::after {
        content: 'L';
        speak: never; /* Pour être sûr que le lecteur d'écran ne lira pas "fois" */
        position: absolute;
        display: inline-block;
        left: 4px;
        top: calc(50% - 2px);
        font-size: 28px;
        color: #FF325D;
        line-height: 0;
        transition: all .2s; /* Petite transition */
    }

    /* Aspect non cochée */
    [type="checkbox"]:not(:checked) + label::after {
        opacity: 0;
        transform: translate(0,-50%) scaleX(-0.8) rotate(-50deg);
    }

    /* Aspect cochée */
    [type="checkbox"]:checked + label::after {
        opacity: 1;
        transform: translate(0,-50%) scaleX(-1) rotate(-36deg);
    }
}
.input-checkbox-error{
    [type="checkbox"]:checked + label::before,
    [type="checkbox"]:not(:checked) + label::before{
        border: 2px solid #ff8000;
    }
}
</style>