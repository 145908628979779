<template>
    <div class="index form-index form-body">
        <BaseButtonBack
            class="back-button-wrapper"
            @click="goBack"/>
        <div class="top-gradient"></div>
        <h1>Attestation sur l’honneur d’utilisation d’un transport* à mobilité durable</h1>
        <section class="warning">
            <div class="warning-wrapper">
                <div class="warning-line-gradient"></div>
                <p class="semi-bold-paragraph">Attention : Une fois votre déclaration transmise, aucune modification ne pourra être apportée.<br> Confirmez-vous votre déclaration ci-dessous ?</p>
            </div>
        </section>
        <section>
            <h2>Votre déclaration</h2>
            <div class="round-corner-grey form-content verify-content">
                <div class="info">
                    <p>Je soussigné·e {{formObject.first_name}} {{formObject.last_name}}</p>
                    <p>né·e le  {{ computeBirthDate }} </p>
                    <p>et demeurant au {{ computeAdress }}</p>
                    <p>Atteste sur l’honneur avoir utilisé à l’occasion de mes déplacements domicile-travail :</p>
                </div>
                <div class="choice">
                    <ul>
                        <li v-if="formObject.mobility_bike">Vélo personnel (mécanique ou à assistance électrique)</li>
                        <li v-if="formObject.mobility_shared_personal">Engins de déplacement personnels partagés (vélopartage, trottinettes et scooters en freefloating, etc…)</li>
                        <li v-if="formObject.mobility_shared_vehicle">Véhicules en autopartage</li>
                    </ul>
                </div>
                <div class="period">
                    <p v-if="formObject.trip_days_count">Pour {{formObject.trip_days_count}} jours d’utilisation sur {{ computePaymentPeriod }}</p>
                    <p v-if="formObject.trip_count">Pour {{formObject.trip_count}} trajets réalisés sur {{ computePaymentPeriod }}</p>
                    <p v-if="formObject.trip_distance_sum">Pour {{formObject.trip_distance_sum}} kilomètres parcourus sur {{ computePaymentPeriod }}</p>
                </div>
                <div class="contact">
                    <div class="flex-line">
                        <img src="@/assets/icn_email.svg" class="icon-contact" alt="envelop email">
                        <p>{{formObject.email}}</p>
                    </div>

                    <div class="flex-line">
                        <img src="@/assets/icn_phone.svg" class="icon-contact" alt="telephone portable">
                        <p>{{formObject.phone}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="data-policy-wrapper checkbox-wrapper round-corner-grey">
                <InputCheckbox
                    v-model="allowDataShare"
                    id="fmd-check"
                    :error="displayError"
                >
                    J’accepte, pour permettre la distribution du forfait mobilités durables, que mes données personnelles ci-dessus soient transmises à mon employeur par l’intermédiaire de Karos.
                    <span class="orange-text">*</span>
                </InputCheckbox>
            </div>
            <BaseError v-if="displayError" label="Obligatoire"/>
        </section>
        <section>
            <div class="cta-aligner">
                <BaseButton
                    :class="{'disable': isPosting}"
                    label="Confirmer l'envoi"
                    @click="sendToApi"/> 
            </div>
        </section>
    </div>
</template>
<script>
import FormInputs from '@/lib/FormInputs'
import BaseButton from '@/components/BaseButton.vue'
import BaseButtonBack from '@/components/BaseButtonBack.vue'
import BaseError from '@/components/BaseError.vue'
import InputCheckbox from '@/components/InputCheckbox.vue'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import Axios from "axios"

export default {
    data () {
        return {
            isPosting: false,
            allowDataShare: false,
            displayError: false
        }
    },
    computed: {
        ...mapGetters({
            gToken: "getReCaptchaToken",
            apiUrl: "getApiUrl",
            formObject: "getFormObject",
            monthInput: "getMonth",
            formParameters: 'getFormParameters',
            yearInput: "getYear",
        }),
        computeAdress () {
            return `${this.formObject.address}, ${this.formObject.postal_code} ${this.formObject.city}`
        },
        computeBirthDate () {
            return dayjs(this.formObject.birth_date).format('DD/MM/YYYY')
        },
        computePaymentPeriod () {
            return this.formParameters.payment_frequency == 'annual' ? `l'année ${this.yearInput}` : `le mois de ${this.monthInput} ${this.yearInput}`
        }
    },
    components: {
        BaseButton,
        BaseButtonBack,
        BaseError,
        InputCheckbox
    },
    mounted () {
        this.getRecaptchToken()
    },
    watch: {
        allowDataShare(val) { this.displayError = false}
    },
    methods:{
        goBack () {
            this.$router.push('/')
        },
        async sendToApi() {
            if (!this.allowDataShare) return this.displayError = true
            let obj = { "g-recaptcha-response": this.gToken }
            Object.assign(obj, this.formObject)
            if (process.env.NODE_ENV != 'production') Object.assign(obj, {mode: 'dev'})
            this.isPosting = true
            try {
                const res = await Axios.post(`${this.apiUrl}index.php`, obj)
                this.isPosting = false
                this.$router.push('/send')
            } catch (error) {
                this.isPosting = false
                console.log(error)
            }
        },
        async getRecaptchToken () {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha()
            this.$store.commit('updateReCaptchaToken', token)
        }
    }
}
</script>
<style lang='scss'>
@import "@/styles/verify.scss";
</style>