<template>
    <div class="input-number-component light-shadow">
        <label class="hidden-label" >{{ label }}</label>
        <input
            class="semi-bold-paragraph"
            type="number"
            :value="modelValue"
            @blur="sanitizeInput"
            min="0"
            @keypress="isKeyAllowed"
            @paste.prevent
            @input="updateInput"
            controls="false"
            :name="label"
            :class="{'input-error': error}">
        <p class="semi-bold-paragraph input-placeholder" v-if="!modelValue || modelValue.length == 0">{{label}}<span class="orange-text" v-if="required">*</span></p>
    </div>
</template>
<script setup>
defineProps({
    modelValue: String,
    label: String,
    required: Boolean,
    error: Boolean
})

const emit = defineEmits(['update:modelValue'])

const isKeyAllowed = (event) => {
    const keysForbiden = ['-', '+', '.', ',', 'e']
    const keyPressed = event.key
    if (keysForbiden.includes(keyPressed)) event.preventDefault()
}

const sanitizeInput = (event) => {
    let value = parseInt(event.target.value)
    emit('update:modelValue', `${value}`)
}

const updateInput = event => {
    let value = event.target.value
    emit('update:modelValue', value)
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.input-number-component{
    border-radius: 5px;
    display: inline-block;
    background: white;
    height: 48px;
    margin: 0 10px;
    position: relative;
    .hidden-label{
        display: none;;
    }
    input{
        border-radius: 5px;
        border:none;
        height: 48px;
        padding: 0 10px;
        font-size: 14px;
        width: calc(100% - 20px);
    }
    .input-placeholder{
        position: absolute;
        top:50%;
        left:10px;
        font-size: 14px;
        margin: 0;
        transform: translate(0,-50%);
        pointer-events: none;
    }
    .input-error{
        border: #FF8000 1px solid;
    }
}

@media screen and (max-width: 764px) {
    .input-text-component{
        width: 100%;
        margin: 10px 0;
    }

}
</style>