<template>
    <div class="index form-index light-shadow form-body">
        <div class="top-gradient"></div>
        <h1>Attestation sur l’honneur d’utilisation d’un transport* à mobilité durable</h1>
        <section class="reminder">
            <h2>Rappels</h2>
            <div class="round-corner-grey reminder-content">
                <p class="regular-paragraph">{{ formParameters.modalities_description }}</p>
                <p class="regular-paragraph">{{ formParameters.distribution_description }}</p>
            </div>
        </section>
        <section>
            <h2>Votre déclaration</h2>
            <div class="round-corner-grey form-content">
                <div class="input-wrapper input-text-line">
                    <p class="input-line-label semi-bold-paragraph">Je soussigné·e</p>
                    <InputText
                        v-model="first_name"
                        :required="true"
                        :error="isInputCorrupt('first_name')"
                        label="Prénom" />
                    <InputText
                        v-model="last_name"
                        :required="true"
                        :error="isInputCorrupt('last_name')"
                        label="Nom" />
                    <p class="input-line-label semi-bold-paragraph">né·e le </p>
                    <InputText
                        autofillLabel="birthdate"
                        v-model="birth"
                        :required="true"
                        :error="isInputCorrupt('birth')"
                        label="JJ/MM/AAAA" />
                </div>
                <BaseError v-if="['first_name','last_name','birth'].some(isInputCorrupt)" />
                <div class="input-wrapper input-text-line">
                    <p class="input-line-label semi-bold-paragraph">Demeurant au</p>
                    <InputText
                        v-model="address"
                        id="adess-input"
                        style="flex:4;"
                        :required="true"
                        :error="isInputCorrupt('address')"
                        label="Adresse" />
                    <div class="predictions-wrapper" v-click-outside="resetPrediction" v-if="predictions && predictions.length > 0">
                        <div class="prediction prediction-close" @click="resetPrediction">
                            <p>SUGGESTIONS</p>
                            <img src="@/assets/icn_close.svg" alt=""></div>
                        <PredictionItem
                            v-for="(prediction, pIndex) in predictions"
                            :key="`prediction-${pIndex}`"
                            :prediction="prediction"
                            @click="selectPrediction(prediction)"
                            />
                    </div>
                    <InputText
                        v-model="postal_code" 
                        style="flex:2.5;"
                        :required="true"
                        :error="isInputCorrupt('postal_code')"
                        label="Code Postal" />
                    <InputText
                        v-model="city"
                        style="flex:3;"
                        :required="true"
                        :error="isInputCorrupt('city')"
                        label="Ville" />
                </div>
                <BaseError v-if="['address','postal_code','city'].some(isInputCorrupt)" />
                <div class="input-wrapper input-checkbox-honor">
                    <p class="semi-bold-paragraph">Atteste sur l’honneur avoir utilisé à l’occasion de mes déplacements domicile-travail, l’un des moyens de transport suivants :</p>
                    <InputCheckbox
                        v-if="formParameters.mobility_bike"
                        class="checkbox-line"
                        v-model='mobility_bike'
                        id="mobility_bike"
                        :error="isInputCorrupt('mobility_choice')"
                    >
                        Vélo personnel (mécanique ou à assistance électrique)
                    </InputCheckbox>
                    <InputCheckbox
                        v-if="formParameters.mobility_shared_personal"
                        class="checkbox-line"
                        v-model='mobility_shared_personal'
                        id="mobility_shared_personal"
                        :error="isInputCorrupt('mobility_choice')"
                    >
                        Engins de déplacement personnels partagés (vélopartage, trottinettes et scooters en freefloating, etc…)
                    </InputCheckbox>
                    <InputCheckbox
                        v-if="formParameters.mobility_shared_vehicle"
                        class="checkbox-line"
                        v-model='mobility_shared_vehicle'
                        id="mobility_shared_vehicle"
                        :error="isInputCorrupt('mobility_choice')"
                    >
                        Véhicules en autopartage
                    </InputCheckbox>
                    <BaseError v-if="isInputCorrupt('mobility_choice')" label="Cochez au moins un moyen de transport que vous déclarez."/>
                </div>
                <BaseError v-if="['bike','rideDeviceShare','carShare'].every(isInputCorrupt)" />
                <div class="input-wrapper input-time">
                    <div class="input-text-line">
                        <p>Votre déclaration concerne {{ formParameters.payment_frequency != 'annual' ? "le mois" : "l'année"}} <span class="orange-text">*</span>:</p>
                        <InputSelect
                            v-if="formParameters.payment_frequency != 'annual'"
                            v-model="month"
                            :required="true"
                            :error="isInputCorrupt('month')"
                            :options="computeMonthOptions"
                            label="Janvier" />
                        <InputSelect
                            v-model="year"
                            :required="true"
                            :options="computeYearOptions"
                            :error="isInputCorrupt('years')"
                            :label="`${new Date().getFullYear()}`" />
                    </div>
                    <div class="input-text-line" v-if="formParameters.require_days">
                        <p>Utilisation estimée en nombre de jours <span class="orange-text">*</span>:</p>
                        <InputNumber
                            v-model="require_days"
                            :error="isInputCorrupt('require_days')"
                            label="ex: 100"
                            class="input-small-number"/>
                    </div>
                    <div class="input-text-line" v-if="formParameters.require_trips">
                        <p>Utilisation estimée en nombre de trajets réalisés  <span class="orange-text">*</span>:</p>
                        <InputNumber
                            v-model="require_trips"                            
                            :error="isInputCorrupt('require_trips')"
                            label="ex: 100"
                            class="input-small-number"/>
                    </div>
                    <div class="input-text-line" v-if="formParameters.require_distance">
                        <p>Utilisation estimée en nombre de kilomètres parcourus <span class="orange-text">*</span>: </p>
                        <InputNumber
                            v-model="require_distance"
                            :error="isInputCorrupt('require_distance')"
                            label="ex: 100"
                            class="input-small-number"/>
                    </div>
                </div>
                <BaseError v-if="['require_days','require_trips','require_distance'].some(isInputCorrupt)"/>
                <div class="input-wrapper input-car-company">
                    <InputCheckbox
                        class="checkbox-line"
                        v-model='certify'
                        id="certify"
                        :error="isInputCorrupt('certify')"
                    >
                        Je certifie que mes trajets déclarés ci-dessus n’ont pas été réalisés avec un véhicule de fonction.<span class="orange-text">*</span>
                    </InputCheckbox>
                </div>
                <BaseError v-if="isInputCorrupt('certify')" label="Obligatoire"/>
            </div>

        </section>
        <section>
            <h2>Vos coordonnées</h2>
            <div class="round-corner-grey form-content">
                <div class="input-text-line">
                    <InputText
                        v-model="email"
                        :required="true"
                        :error="isInputCorrupt('email')"
                        label="Email" />
                    <InputText
                        v-model="phone" 
                        :required="true"
                        :error="isInputCorrupt('phone')"
                        label="Téléphone" />
                </div>
                <BaseError v-if="['email','phone'].some(isInputCorrupt)" />
            </div>
        </section>
        <section>
            <div class="cta-aligner">
                <BaseButton
                    label="Valider"
                    @click="checkForm"/> 
            </div>
        </section>
    </div>
</template>
<script>
import InputText from '@/components/InputText.vue'
import InputCheckbox from '@/components/InputCheckbox.vue'
import InputSelect from '@/components/InputSelect.vue'
import InputNumber from '@/components/InputNumber.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseError from '@/components/BaseError.vue'
import PredictionItem from '@/components/PredictionItem'
import FormInputs from '@/lib/FormInputs'
import { isValidPhoneNumber } from 'libphonenumber-js'
import Places from '@/lib/Places.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default {
    data () {
        return {
            certify: false,
            predictions: [],
            errors: [],
            parsedBirth: '',
            isDetailedAdess: false,
            mailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        }
    },
    computed: {
        ...mapGetters({
            formParameters: 'getFormParameters',
            holding_id: 'getHoldingId',
            isFormValidated: 'getFormValidated',
            formObject: "getFormObject"
        }),
        ...FormInputs
    },
    watch:{
        address: async function (val) {
            if (val.length < 4 ) return
            if (!this.isDetailedAdess) {
                const res = await Places.getPredictions(val)
                this.predictions = res.data.predictions    
            }
            this.isDetailedAdess = false
        }
    },
    components: {
        BaseButton,
        BaseError,
        PredictionItem,
        InputSelect,
        InputText,
        InputCheckbox,
        InputNumber
    },
    mounted () {
        dayjs.extend(customParseFormat)
    },
    methods:{
        validate () {
            this.parsedBirth = dayjs(this.birth, ["DD/MM/YYYY", "DD/MM/YY"])
            this.errors = []
            if (this.first_name.length <= 1 ) this.errors.push('first_name')
            if (this.last_name.length <= 1 ) this.errors.push('last_name')
            if (!this.parsedBirth.isValid()) this.errors.push('birth')
            if (this.address.length <= 4 ) this.errors.push('address')
            if (this.postal_code.length != 5 ) this.errors.push('postal_code')
            if (this.city.length <= 1 ) this.errors.push('city')
            if (!this.certify) this.errors.push('certify')
            if (!this.email.match(this.mailRegex)) this.errors.push('email')
            if (!isValidPhoneNumber(this.phone, 'FR')) this.errors.push('phone')
            if (!this.mobility_bike && !this.mobility_shared_personal && !this.mobility_shared_vehicle) this.errors.push('mobility_choice')
            if (this.formParameters.require_days 
                && (!this.require_days || this.require_days.length == 0 )) this.errors.push('require_days')
            if (this.formParameters.require_trips 
                && (!this.require_trips || this.require_trips.length == 0 )) this.errors.push('require_trips')
            if (this.formParameters.require_distance 
                && (!this.require_distance || this.require_distance.length == 0 )) this.errors.push('require_distance')

            const isValid = this.errors.length == 0
            this.$store.commit('updateValidState', isValid)
            return isValid
        },
        isInputCorrupt (input) {
            return this.errors.indexOf(input) != -1
        },
        checkForm () {
            const isFormOk = this.validate()
            if (!isFormOk) return console.log(this.errors)
            this.$store.dispatch('computeFormObject')
            this.$router.push('/verify')
        },
        resetPrediction () {
            this.predictions = []
        },
        async selectPrediction (prediction) {
            const res = await Places.getDetails(prediction)
            if (res.status == 200 && res.data.result) {
                const placeDetails = res.data.result
                this.isDetailedAdess = true
                this.address = placeDetails.name
                this.city = placeDetails.vicinity
                const postal_object = placeDetails.address_components.find(el => el.types.indexOf('postal_code')!= -1 )
                if(typeof postal_object != 'undefined') this.postal_code = postal_object.long_name
                this.predictions = []
            }
        },
    }
}
</script>
<style lang='scss'>
@import "@/styles/form.scss";

</style>