import { createStore } from 'vuex'
import form from './form.js'

const API_URLS = {
    'production': 'https://declarationfmd.karos.fr/',
    'preprod': 'https://declarationfmd-preprod.karos.fr/',
    'development': 'http://localhost/~arthur/mobility-package-form/'
}

export default createStore({
    modules: {
        form
    },
    state: {
        isMobile: false,
        reCaptchaToken: '',
        holding_id: '',
        apiUrl: API_URLS[process.env.NODE_ENV]
    },
    mutations:{
        updateHoldingId (state, id) {
            state.holding_id = id
        },
        updateReCaptchaToken(state, token) {
            state.reCaptchaToken = token
        }
    },
    getters: {
        getReCaptchaToken: state => state.reCaptchaToken,
        getFormPostUrl: state => state.formPostUrl,
        getApiUrl: state => state.apiUrl,
        getHoldingId: state => state.holding_id
    }
})