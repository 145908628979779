<template>
    <div class="base-error-wrapper">
        <img class="error-icon" src="@/assets/icn_warning_triangle.svg" alt="">
        <p class="error-label">{{ label }}</p>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Champs obligatoire'
        }
    }
}
</script>
<style lang="scss">
.base-error-wrapper{
    position: relative;
    display: flex;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
    .error-label{
        color:#FF8000;
        margin:0;
        font-family: Raleway-semi-bold;
        font-style: italic;
        font-size: 11px;
    }
    .error-icon{
        width: 16px;
        height: 16px;
    }
}
</style>
