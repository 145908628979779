import Axios from "axios"
class Places {
    constructor (key, selector) {

        this.baseUrl = this.getUrl()
        this.sessionToken = this.setToken()
    }
    setToken () {
        return Math.random().toString(36).substring(4);
    }
    getPredictions(str) {
        var params = `sessiontoken=${this.sessionToken}&input=${str}&route=autocomplete`
        return Axios.get(`${this.baseUrl}?${params}`) 
    }
    getDetails (place) {
        var params = `sessiontoken=${this.sessionToken}&place_id=${place.place_id}&route=details`
        return Axios.get(`${this.baseUrl}?${params}`)
    }
    getUrl() {
        var env_url = {
            development: 'http://127.0.0.1/~arthur/mobility-package-form/places.php',
            preprod: '/places.php',
            production: '/places.php',
        }
        return env_url[process.env.NODE_ENV]
    }
}
export default new Places()
