<template>
    <div class="back-cta light-shadow">
        <img class="cta-icon" src="@/assets/icn_arrow.svg" alt="">
        <p class="cta-label">Retour</p>
    </div>
</template>
<style lang="scss">
.back-cta {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    height: 39px;
    width: 99px;
    padding: 0 10px;
    border-radius: 40px;
    cursor: pointer;
    .cta-label {
        font-family: Cocogoose-semi-light;
        font-size: 12px;
        color:#3C3C3C;
    }
    .cta-icon {
        width: 18px;
        height: 18px;
    }
}
</style>