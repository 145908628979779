import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import inputsComputed from '@/lib/FormInputs'
dayjs.extend(customParseFormat)

export default {
    state: () => ({
        inputs: {
            'first_name': '',
            'last_name': '',
            'birth': '',
            'address': '',
            'postal_code': '',
            'city': '',
            'year': `${new Date().getFullYear()}`,
            'month': 'Janvier',
            'email': '',
            'phone': '',
        },
        formParameters: {},
        formObject: {},
        isFormValidated: false
    }),
    mutations: {
        updateFormObject(state, form) {
            state.formObject = form
        },
        updateValidState(state, isValid) {
            state.isFormValidated = isValid
        },
        updateFormProps(state, {props, value}) {
            state.inputs[props] = value
        },
        updateFormParameters(state, value) {
            state.formParameters = value
        }
    },
    actions: {
        computeFormObject ({state, getters, commit}) {
            let formated_birth = dayjs(state.inputs.birth,["DD/MM/YYYY", "DD/MM/YY"])
            let formObject = {
                'holding_id': getters.getHoldingId,
                'first_name': state.inputs.first_name,
                'last_name': state.inputs.last_name,
                'birth_date': formated_birth.format('YYYY-MM-DD'),
                'frequency': state.formParameters.payment_frequency,
                'address': state.inputs.address,
                'postal_code': state.inputs.postal_code,
                'city': state.inputs.city,
                'email': state.inputs.email,
                'phone': state.inputs.phone
            }
            if (state.formParameters.mobility_bike) formObject['mobility_bike'] = state.inputs.mobility_bike
            if (state.formParameters.mobility_shared_personal) formObject['mobility_shared_personal'] = state.inputs.mobility_shared_personal
            if (state.formParameters.mobility_shared_vehicle) formObject['mobility_shared_vehicle'] = state.inputs.mobility_shared_vehicle
            if (state.formParameters.require_days) formObject['trip_days_count'] = state.inputs.require_days
            if (state.formParameters.require_trips) formObject['trip_count'] = state.inputs.require_trips
            if (state.formParameters.require_distance) formObject['trip_distance_sum'] = state.inputs.require_distance
            if (state.formParameters.payment_frequency != 'annual') {
                const monthIndex = inputsComputed.computeMonthOptions().indexOf(state.inputs.month) + 1
                const computedPeriod = `01-${monthIndex}-${state.inputs.year}`
                formObject["period_date"] = dayjs(computedPeriod, 'DD-M-YYYY').format('YYYY-MM-DD')
            }   else {
                formObject["period_date"] = `${state.inputs.year}-01-01`
            }
            commit('updateFormObject', formObject)
        }
    },
    getters: { 
        getAllowDataShare: state => state.allowDataShare,
        getFirstname: state => state.inputs.first_name,
        getLastname: state => state.inputs.last_name,
        getMonth: state => state.inputs.month,
        getYear: state => state.inputs.year,
        getFormObject: state => state.formObject,
        getFormValidated: state => state.isFormValidated,
        getFormParameters: state => state.formParameters,
        getHoldingLogo: state => state.formParameters ? state.formParameters.logo_url : ''
    }
}