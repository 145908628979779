import { createRouter, createWebHashHistory } from 'vue-router'
import Store from '@/store/'
import Home from '@/views/index.vue'
import Error from '@/views/error.vue'
import Verify from '@/views/verify.vue'
import End from '@/views/end.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/verify', component: Verify },
    { path: '/send', component: End },
    { path: '/404', component: Error, name: 'error' }
]
const router =  createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    let protectedRoute = ['/verify', '/send']
    if (protectedRoute.includes(to.path) && !Store.state.form.isFormValidated) next({ path: '/' })
    else next()
})
export default router