<template>
    <div class="cta-wrapper">
        <p class="cta-label">{{label}}</p>
    </div>
</template>
<script>
export default {
    props: {
        label: String
    }
}
</script>
<style lang="scss">
.cta-wrapper{
    cursor: pointer;
    border-radius: 3px;
    height: 40px;
    display: inline-block;
    padding: 0 30px;
    background: linear-gradient(90deg, #FF7D63 0%, #FF325D 100%);
    .cta-label{
        display: inline-block;
        font-family: Raleway-bold;
        color: white;
        line-height: 40px;
        margin: 0;
        letter-spacing: 0.5px;
    }
}
</style>