<template>
    <div class="prediction">
        <p class="adress-line">{{ prediction.structured_formatting.main_text }}</p>
        <p class="city-line">{{ prediction.structured_formatting.secondary_text }}</p>
    </div>
</template>
<script>
export default {
    props: {
        prediction: Object
    },
    methods: {
    }
}
</script>
<style lang="scss">
.prediction{
    position: relative;
    padding: 10px 20px;
    p{
        margin: 0;
    }
    .adress-line{
        font-size: 14px;
        margin-bottom: 6px;
    }
    .city-line{
        font-size: 12px;
        color:#888888;
    }  
    
    cursor: pointer;
    &:hover{
        background: #f7f7f7;
    }
    &:after{
        content: '';
        display: block;
        height: 1px;
        width: 90%;
        position:absolute;
        left: 5%;
        top: 100%;
        background: #DCE0E0;
    }
}


</style>