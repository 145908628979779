import { createApp } from 'vue'
import App from './App.vue'
import store from './store/'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const vueApp = createApp(App)
vueApp.use(store)
vueApp.use(router)
vueApp.use(VueReCaptcha, { siteKey: "6LdFCWwdAAAAANZgmA16kd9w3c-5zOvput5xaFoL" })
vueApp.mount('#app')

const clickOutside = {
    beforeMount (el, binding, vnode, prevVnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value()
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
}

vueApp.directive('click-outside', clickOutside);