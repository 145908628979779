<template>
    <div class="input-select-component light-shadow" 
            :class="{'input-error': error}">
        <select  :value="modelValue" @input="updateInput" class="semi-bold-paragraph">
            <option v-for="(option, index) in options" :key="`${label}-${index}`">{{option}}</option>
        </select>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    setup () {
        const inputEl = ref(null)
        return { inputEl }
    },
    props: {
        modelValue: String,
        label: String,
        required: Boolean,
        options: Array,
        error: Boolean
    },
    emits: ['update:modelValue'],
    methods: {
        updateInput(event) {
            let value = event.target.value
            //this.computeWidth(value.length)
            this.$emit('update:modelValue', value)
        },
        computeWidth (length) {
            this.charWidth = 8
            let width = length * this.charWidth
            width = Math.min(Math.max(width, 100),300)
            this.inputEl.style.width =  `${width}px`
        }
    }
}
</script>
<style lang="scss">
.input-select-component{
    border-radius: 5px;
    display: inline-block;
    background: white;
    height: 48px;
    padding:0 10px; 
    margin: 0 10px;
    
    position: relative;
    select{
        height: 100%;
        border: none;
        border-radius: 5px;
    }
    option{
        font-family: Raleway;
    }
    .input-error{
        border: #FF8000 1px solid;
    }
}
@media screen and (max-width: 764px) {
    .input-select-component{
        margin: 10px 10px 10px 0px;
    }
    
}
</style>